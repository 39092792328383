import { Box, Container, Grid } from '@mui/material'
import React, { useContext } from 'react'
import { useState } from 'react'
import Footer from './Components/Footer'
import FullScreenLoader from './Components/fullScreenLoader'
import HeroSection from './Components/HeroSection'
import Navbar from './Components/Navbar'
import EmailForm from './Components/EmailForm'

const About = () => {
  const [loader, setLoader] = useState(true)
  setTimeout(() => {
    setLoader(false)
  }, 4000);

  return (
    <div style={{marginTop:120}}>
   
      <>
 <Navbar />
 <div className='about_us' style={{textAlign:'center',marginTop:40}}>
   <h2>About Us</h2>
   <h3 style={{marginTop:20}}>Al Musawir Islamic Store <br/> Your Trusted Manufacturer & Exporter</h3>
   <Container>
   <Box sx={{ flexGrow: 1, marginTop:'60px' }}>
      <Grid container spacing={2}>
        <Grid style={{textAlign:'left'}} lg={12} md={12} sm={12} xs={12}>
        <div>

        Al Musawir Islamic Store proudly leads as a manufacturer and exporter of premium Islamic products. We operate our own processing and manufacturing units to ensure the highest standards of quality and authenticity. </div>

<div style={{marginTop:20}}> <b> Our Products Include:  </b> </div> <br />
<div>

🌿 Miswak and Lobaan Javy: Explore our range of meticulously processed Miswak and Lobaan Javy products. </div>

🏺 Attar Bottle Caps and Die Cast Coating: Discover our specialized manufacturing unit producing Attar bottle caps with precision die-cast coating. <br/>


👳‍♂️ Islamic Kufi Hats: Enhance your attire with our collection of traditional Islamic Kufi hats. <br/>

🕌 Brass Handicrafts: Adorn your space with our exquisite brass handicrafts featuring intricate Islamic designs. <br/>

<div style={{marginTop:20}}><b> Why Choose jm Musawir Islamic Store? </b> </div> <br/>

At Al Musawir Islamic Store, we are committed to delivering exceptional products that reflect our dedication to Islamic values and craftsmanship. Whether for personal use or as gifts, our diverse range ensures there's something for everyone. <br/>

Explore our complete collection online or contact us directly to learn more about how we can meet your specific needs.


        </Grid>
       
      </Grid>
    </Box>

   
  
 
   </Container>
 </div>
 <Footer />
 </>
   
   
    </div>
  )
}

export default About
