import React from 'react';
import { useState } from 'react'
import { uploadImage } from '../../firebase/firebaseMethod1';
import { Box, CircularProgress, Grid } from "@mui/material";
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import app from '../../firebase/firebaseconfig';
import { Button } from '../../styles/Button';
 
const AddNewProduct = () => {
const [isLoading, setLoading] =useState(false) 
const [product, setProduct] =useState([])
const [image, setImage] =useState('')




    const uploadProduct = () => {
        setLoading(true)
        console.log(image);
        uploadImage(image, "Images", product, "Products").then(() => {
            alert("Successfully upload")
            setLoading(false)
            // window.location.reload()
        }).catch(() => {
            setLoading(false)
            alert("sOMETHING IS WRONG");
        })
    }


    return(
    
<div>
<Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <input style={{border:'none', borderBottomWidth:1, borderBottomColor:'black', borderBottomStyle:'solid', backgroundColor:'#f8f9fa'}} placeholder='Product Name' variant='standard' onChange={e => setProduct({ ...product, name: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <input style={{border:'none', borderBottomWidth:1, borderBottomColor:'black', borderBottomStyle:'solid', backgroundColor:'#f8f9fa'}} placeholder='Product Price' variant='standard' onChange={e => setProduct({ ...product, price: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <input style={{border:'none', borderBottomWidth:1, borderBottomColor:'black', borderBottomStyle:'solid', backgroundColor:'#f8f9fa'}} placeholder='Product Special Price' variant='standard' onChange={e => setProduct({ ...product, sPrice: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <input style={{border:'none', borderBottomWidth:1, borderBottomColor:'black', borderBottomStyle:'solid', backgroundColor:'#f8f9fa'}} placeholder='Stock' variant='standard' onChange={e => setProduct({ ...product, stock: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <input style={{border:'none', borderBottomWidth:1, borderBottomColor:'black', borderBottomStyle:'solid', backgroundColor:'#f8f9fa'}} placeholder='Product Description' variant='standard' onChange={e => setProduct({ ...product, desc: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <label style={{fontSize:12}}>Select Image</label>
                        <input type="file"  style={{border:'none', borderBottomWidth:1, borderBottomColor:'black', borderBottomStyle:'solid', backgroundColor:'#f8f9fa'}} onChange={e =>{setImage(e.target.files[0])}} />
                    </Grid>
                    <Button onClick={uploadProduct}>{isLoading? <CircularProgress /> : 'Submit'}</Button>
                </Grid>
            </Box>
</div>
    )
};

export default AddNewProduct;