import Grid from '@mui/material/Grid';
import { Box, Container } from '@mui/system'
import React from 'react'
import { Button } from '../styles/Button'
import './footer.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


const Footer = () => {
  return (
    <>
      <Wrapper>
      <div className='what-btn'>
      <a className="qlwapp-toggle whatsapp" 
  href="https://wa.me/+923452508923" target="_blank">
  <WhatsAppIcon className='whatsapp'/>
</a>
      </div>
        <Container>
          <Box sx={{ backgroundColor: 'black', color: '#926c15', fontSize:'120%' }} className='mainbox'>
            <Box>
              <h4>Ready to get Started</h4>
              <h4>Talk to us today</h4>

            </Box>
            <Box>
              <Button>
                <NavLink to='/contact' style={{color:'white'}}>
                  Get Started
                </NavLink>
              </Button>
            </Box>
          </Box>
        </Container>
        <Box className='footer'>

          <Box sx={{ flexGrow: 1, ml: 5 }}>
            <Grid container spacing={2}>
              <Grid item lg={4} md={4} sm={12} xs={12} className='footer-about'>
                <h3 style={{ fontSize: '150%', color: 'white' }}>FOLLOW US</h3>
                
                <div className='footer-social--icons'>
                  <a href="https://www.facebook.com/share/6GFNoghqBGWjyQJx/?mibextid=qi2Omg">
                    <div className='iconBoxF'>
                      <FacebookIcon className='icons' />
                    </div>
                  </a>

                
                  
                </div>
                <form action="https://formspree.io/f/mnqydkra" method="POST">
                <input style={{ marginTop: 20, padding: '20px', outline:'none',border:'none' }} type="email" name="subscribe" placeholder="Your Email" required autoComplete="off" id="" /> <br />
                <button style={{ backgroundColor: 'black', color: 'white', padding: 10, fontWeight: 'bold', cursor: 'pointer', marginTop: 10 }}>Subscribe</button>
                </form>
              </Grid>

              <Grid item lg={4} md={4} sm={12} xs={12}>
                <h3 style={{ fontSize: '150%', color: 'white' }}>INFORMATION</h3>
                <NavLink to='/about'>
                  <p style={{ fontSize: '120%', color: 'white' }}>About Us</p>
                </NavLink>
                <NavLink to='/shipping-information'>
                  <p style={{ fontSize: '120%', color: 'white' }}>Shipping Information</p>
                </NavLink>
                <a target='_blank' href='https://www.leopardscourier.com/leopards-tracking'>
                  <p style={{ fontSize: '120%', color: 'white' }}>Track Order (For Pak Only)</p>
                </a>
                <a target='_blank' href='https://maps.app.goo.gl/N1G8HpL59tABjxDu8'>
                  <p style={{ fontSize: '120%', color: 'white' }}>Store Locator</p>
                </a>
              </Grid>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <h3 style={{ fontSize: '150%', color: 'white' }}>CUSTOMER CARE</h3>
                <NavLink to='/contact'>
                  <p style={{ fontSize: '120%', color: 'white' }}>Contact Us</p>
                </NavLink>
                <NavLink to='/privacy-policy'>
                  <p style={{ fontSize: '120%', color: 'white' }}>Privacy Policy</p>
                </NavLink>
                <NavLink to='/return-&-exchange'>
                  <p style={{ fontSize: '120%', color: 'white' }}>Return & Exchange Policy</p>
                </NavLink>
                <NavLink to='/review'>
                  <p style={{ fontSize: '120%', color: 'white' }}>Review</p>
                </NavLink>
              </Grid>
            </Grid>

          </Box>

          <div className='footer-bottom--section'>
            <hr />
            <div style={{ display: 'flex' }}>
              <div style={{ marginLeft: 20 }}>
                <h4 style={{ color: 'white' }}>©️ {new Date().getFullYear()} Al-Musawir International</h4>
              </div>
              <div style={{ marginLeft: 20 }}>
                <h4 style={{ color: 'white' }}> All Rights Reserved </h4>
              </div>
              <div style={{ marginLeft: 20 }}>
                <a  href="https://linkdin.com/in/hamzajawed444" target='_blank'><h4 style={{ color: 'white' }}>Develop by Hamza</h4></a>
              </div>
            </div>
          </div>
        </Box>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.section`
  .iSIFGq {
    margin: 0;
  }
  .contact-short {
    max-width: 60vw;
    margin: auto;
    padding: 5rem 10rem;
    background-color: #fef7e5;
    border-radius: 1rem;
    box-shadow: ${({ theme }) => theme.colors.shadowSupport};
    transform: translateY(50%);
    .grid div:last-child {
      justify-self: end;
      align-self: center;
    }
  }
  .footer {
    padding: 8rem 0 1rem 0;
    background-color: ${({ theme }) => theme.colors.footer_bg};
    h3 {
      color: white;
      margin-bottom: 1.5rem;
    }p{
      color: white;
      margin-bottom: 1.5rem;
      font-weight: 100;
    }
    p:hover{
      color: #fffff !important;
    }
    a {
      color: white;
      font-weight: bold;
    }
    .footer-social--icons {
      display: flex;
      gap: 2rem;
      div {
        padding: 10px;
        border-radius: 50%;
        border: 2px solid white;
        .icons {
          color: white;
          font-size: 1.8rem;
          position: relative;
          cursor: pointer;
        }
        .icon:hover{
          color:white !important;
        }
      }
    }
  }
  .footer-bottom--section {
    padding-top: 2rem;
    hr {
      margin-bottom: 2rem;
      color: white;
      height: 0.1px;
    }
  }
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .contact-short {
      max-width: 80vw;
      margin: 4.8rem auto;
      transform: translateY(0%);
      text-align: center;
      .grid div:last-child {
        justify-self: center;
      }
    }
    
    
  }
`;


export default Footer
