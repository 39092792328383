import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { LineChart, axisClasses } from '@mui/x-charts';

import Title from './Title';
import { getData } from '../../firebase/firebasemethod';
import ReactApexChart from 'react-apexcharts';
import ColumnChart from './columnChart';

// Generate Sales Data








export default function Chart() {

  const [data,setData] = React.useState([])
  const [chart, setChart] = React.useState()

  React.useEffect(() => {
    getData('ordersNew')
        .then((res) => {
            setData(Object.values(res))
            console.log(res);
            // setLoading(false)
        })
        .catch((err) => {
            alert(err);
            // setLoading(false)
        })
}, []);

const date = new Date();


let day = date.getDate();
let month = date.getMonth();
let year = date.getFullYear();

let currentDate = `${day}-${month+1}-${year}`;

const chartDate = data.filter((item) => {
    return setChart === '' ? item :
        item.date === currentDate ;
})

let yesterdayDate = `${day-1}-${month+1}-${year}`;

const yesterdayOrders = data.filter((item) => {
    return setChart === '' ? item :
        item.date === yesterdayDate ;
})

let days2Ago = `${day-2}-${month+1}-${year}`;

const days2AgoOrders = data.filter((item) => {
    return setChart === '' ? item :
        item.date === days2Ago ;
})

let days3Ago = `${day-3}-${month+1}-${year}`;

const days3AgoOrders = data.filter((item) => {
    return setChart === '' ? item :
        item.date === days3Ago ;
})


let days4Ago = `${day-4}-${month+1}-${year}`;

const days4AgoOrders = data.filter((item) => {
    return setChart === '' ? item :
        item.date === days4Ago ;
})


let days5Ago = `${day-5}-${month+1}-${year}`;

const days5AgoOrders = data.filter((item) => {
    return setChart === '' ? item :
        item.date === days5Ago ;
})


let days6Ago = `${day-6}-${month+1}-${year}`;

const days6AgoOrders = data.filter((item) => {
    return setChart === '' ? item :
        item.date === days6Ago ;
})




console.log(days2Ago)




// function getWeekDates() {

//   let now = new Date();
//   let dayOfWeek = now.getDay(); //0-6
//   let numDay = now.getDate();
//   let hours =now.getHours();

//   let start = new Date(); //copy
//   start.setDate(numDay - dayOfWeek+2);



//   let end = new Date(); //copy
//   end.setDate(numDay + ( dayOfWeek - 3));

//   return [start, end];
// }

// let [start, end] = getWeekDates();

// console.log(start.toLocaleString(), end.toLocaleString());


// const weekOrders = data.filter((item) => {
//   return setChart === '' ? item :
//   item.date >=  start && item.date <= end;
  
// })

// console.log(weekOrders)


    const chartName = {
      
        series: [{
            name: "Desktops",
            data: [chartDate.length, yesterdayOrders.length, days2AgoOrders.length, days3AgoOrders.length, days4AgoOrders.length, days5AgoOrders.length, days6AgoOrders.length]
        }],
        options: {
          chart: {
            height: 250,
            type: 'line',
            zoom: {
              enabled: true
            }
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'straight'
          },
          title: {
            text: 'Sales Anaylatics',
            align: 'left'
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          xaxis: {
            categories: [currentDate, yesterdayDate, days2Ago, days3Ago, days4Ago, days5Ago, days6Ago],
          }
        },
      
      
      };
        return (
          <div>
            <div id="chart">
              <ReactApexChart options={chartName.options} series={chartName.series} type="line" height={350} />
            </div>
            <div id="html-dist"></div>
            <ColumnChart />
          </div>
        );
      
    }

  

  
  
