import React, { useState } from 'react'
import styled from 'styled-components'
import {FaCheck}  from 'react-icons/fa'
import CardToggleAmount from './CardToggleAmount'
import { NavLink, useNavigate } from 'react-router-dom'
import { Button } from '../styles/Button'
import { useCartContext } from '../context/cartContext'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/material'

const 

AddToCart = ({product, cart}) => {
  const {addToCart} = useCartContext(); 
  // console.log(product)
  const [data, setData] = useState()
  

  const {id, title, sale_price,price, image_link, availability} = product

  const stock = availability

  

    const colors = [{
      colores:'gray',
      name:'Gray'
    },
    {
      colores:'white',
      name:'White'
    },
    {
      colores:'black',
      name:'Black'
    },
    {
      colores:'blue',
      name:'Blue'
    },
    {
      colores:'#d08d03',
      name:'Golden'
    },
   
    {
      colores:'lightgray',
      name:'Lightgray'
    },
  
   
    {
      colores:'#f8f9f8',
      name:'Fon'
    },
    {
      colores:'brown',
      name:'Brown'
    },
    {
      colores:'green',
      name:'Green'
    },
    {
      colores:'maroon',
      name:'Maroon'
    },
    {
      colores:'ferozzygray',
      name:'Ferozy Gray'
    },
    {
      colores:'lightbrown',
      name:'Biscuttii'
    },
    {
      colores:'silver',
      name:'Silver'
    },
    {
      colores:'mehendi',
      name:'Mehendi'
    }
  ]

  console.log(product)

    const sizes = [{
      size:'50/19',
      name:'50/19'
    },
    {
      size:'51/19.5',
      name:'51/19.5'
    },
    {
      size:'52/20',
      name:'52/20'
    },
    {
      size:'53/20.5',
      name:'53/20.5',
    },
    {
      size:'54/21',
      name:'54/21'
    },
    {
      size:'55/21.5',
      name:'55/21.5'
    },
    {
      size:'56/22',
      name:'56/22'
    },
    {
      size:'57/22.5',
      name:'57/22.5'
    },
    {
      size:'58/23',
      name:'58/23'
    },
    {
      size:'59/23.5',
      name:'59/23.5'
    },
    {
      size:'60/24',
      name:'60/24'
    }
  ]
    const [color, setColors] = useState()
    const [size, setSizes] = useState()
    const [amount, setAmount] = useState(1)
    const navigate = useNavigate()
    // console.log(cart)
    const handleChange = (event) => {
      setColors(event.target.value);
      
    };

    const handleChangeSize = (event) => {
      setSizes(event.target.value);
      
    };
 
    
    
    
    // console.log(stock)

    const setDecreament = ()=>{
      amount > 1? setAmount(amount -1): setAmount(1);
    } 
    
    const setIncreament = ()=>{
      amount < stock? setAmount(amount +1): setAmount(stock);

    }

    console.log(product)
    console.log(size)
    console.log(color)
    console.log(amount)
    console.log(id)
    
    return (
    <>
      <Wrapper>
        <div className="colors">
          <p>
            <Box sx={{ minWidth: 120 }}>
      <FormControl style={{width: 150}} fullWidth>
        <InputLabel id="demo-simple-select-label">Colors</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={colors.name}
          label="Colors"
          onChange={handleChange}
        >
            
            {colors.map((x,i)=>{
              return(

       
          <MenuItem key={i} value={x.name}>{x.name}</MenuItem>
        
                // <button title={x.name} 
                // // style={{backgroundColor:x.colores}}
                //  onClick={()=>setColors(x)} className={color.colores === x.colores? 'btnStyle active': 'btnStyle' } key={i}>
                //   {x.name}
                //   {/* {color.colores == x.colores? : null} */}
                // </button>
              )
            })}
            </Select>
      </FormControl>
    </Box>
          </p>
          <p style={{marginTop:15}}>
          <Box sx={{ minWidth: 120 }}>
      <FormControl style={{width: 150}} fullWidth>
        <InputLabel id="demo-simple-select-label">Sizes</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={sizes.name}
          label="Colors"
          onChange={handleChangeSize}
        >
            {sizes.map((x,i)=>{
              return(
                <MenuItem key={i} value={x.size}>{x.size}</MenuItem>
                // <button onClick={()=>setSizes(x)} className={size.size == x.size? 'active btnSize':'btnSize'}>{size.size === x.size? <FaCheck  className='checkStyle'/>: x.size}</button>
              )
            })}
               </Select>
      </FormControl>
    </Box>
          </p>
        </div>
        <CardToggleAmount 
        amount={amount}
        setIncreament={setIncreament}
        setDecreament={setDecreament}
        />

        <NavLink to='/cart' onClick={()=>addToCart(id,color,amount,product,size)}>
          <Button>Add to Cart</Button>
        </NavLink>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.section`
  .colors p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  p:hover{
    color:black;
  }
  .btnStyle {
    width: 7rem;
    height: 2.5rem;
    background-color: transparent;
    border-radius: 0%;
    margin-left: 1rem;
    border: 1px solid black;
    outline: none;
    opacity: 0.5;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  .btnSize {
    width: 2rem;
    height: 2rem;
    color: black;
    background-color:white;
    margin-left: 1rem;
    border: none;
    outline: none;
    opacity: 0.5;
    cursor: pointer;
    border: 1px solid black;
    &:hover {
      opacity: 1;
    }
  }
  .active {
    opacity: 1;
    background-color:#edc531;
    color:white;
    border: 1px solid #edc531;
  }
  .checkStyle {
    font-size: 1rem;
    color: #fff;
  }
  /* we can use it as a global one too  */
  .amount-toggle {
    margin-top: 3rem;
    margin-bottom: 1rem;
    display: flex;
    font-size: 1.4rem;
    button {
      border: none;
      background-color: #fff;
      cursor: pointer;
      padding-left:20px;
      padding-right:20px;
    }
    .amount-style {
      font-size: 2.4rem;
      color: ${({ theme }) => theme.colors.btn};
    }
  }
`;

export default AddToCart
