import { createContext, useContext, useEffect, useReducer } from "react";
import AddToCart from "../Components/AddToCart";
import reducer from '../reducer/cartReducer'

const CartContext = createContext();



const getLocalCartData = ()=>{
    let localCart = localStorage.getItem('AddToCart')

    if(localCart == []){
        return [];
    }else{
        return JSON.parse(localCart);
    }
}




const initialState = {
    // cart:[],
    cart: getLocalCartData(),
    total_item:'',
    total_amount:'',
    shipping_fee: 250,
}

if(initialState.cart == null){
      
    initialState.cart = [];
  
}else{
    getLocalCartData();
}



console.log(initialState.cart)

const CartProvider = ({children}) =>{

    const [state, dispatch] = useReducer(reducer, initialState)

    const addToCart = (id,  color, amount, product, size) => {
        if (!id || !color|| !amount || !product || !size) {
          alert('Add Color');
          return;
        } else {
          dispatch({ type: 'ADD_TO_CART', payload: { id,  color, amount, product, size } });
        }
      };


   


   const removeItem = (id)=>{
    dispatch({type:'REMOVE_ITEM', payload: id})
   }

const clearCart = ()=>{
    dispatch({type:'CLEAR_CART'});
};

useEffect(() => {
    dispatch({ type: "CART_ITEM_PRICE_TOTAL" });
    localStorage.setItem('AddToCart', JSON.stringify(state.cart))
    
}, [state.cart])

// console.log(state)

    return <CartContext.Provider value={{...state, addToCart, clearCart, removeItem}}>{children}</CartContext.Provider>
}

const useCartContext = ()=>{
    return useContext(CartContext)
}

export {CartProvider, useCartContext}