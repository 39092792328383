import { CircularProgress, Grid, Paper, setRef, Table, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { ref, uploadBytes } from 'firebase/storage'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { storage } from '../../firebase/firebaseconfig'
import { deleteItem, Edit, getData, sendData } from '../../firebase/firebasemethod';
import { uploadImage } from '../../firebase/firebaseMethod1';
import { v4 } from 'uuid'
import { Database } from 'firebase/database'
import axios from 'axios'
import { useNavigate, useNavigation } from 'react-router-dom'
import './style.css'
import Base_Url from '../../url'
import { Button } from '../../styles/Button'
import EditNoteIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const AddProductHome = () => {
    const [product, setProduct] = useState([])
    const [getProduct, setGetProduct] = useState([])
    console.log(getProduct);
    let [image, setImage] = useState("");
    const navigate = useNavigate()
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(false)
    const [text, setText] = useState('')






    useEffect(() => {
        getData('Products').then((res) => {
            console.log('product get')
            setGetProduct(Object.values(res))
        }).catch((err) => {
            console.log('product not get ', err)
        })
    }, [refresh])



    const Delete = (e) => {
        deleteItem('Products', e)
        setRefresh(!refresh)
    }

    const uploadProduct = () => {
        setLoading(true)
        console.log(image);
        uploadImage(image, "Images", product, "Products").then(() => {
            alert("Successfully upload")
            setLoading(false)
            setRefresh(!refresh)
            // window.location.reload()
        }).catch(() => {
            setLoading(false)
            alert("sOMETHING IS WRONG");
        })
    }

    const handleClick = () => {
        navigate('Add-Product')
    }

    return (
        <>

           

            <div>
                <Box sx={{ mt: 5 }}>
                    <div style={{display:'flex', justifyContent:'space-between', marginBottom:'20px'}}>
                    <form style={{marginBottom:'-30px'}} onSubmit={(e) => e.preventDefault()}>
                        <input className='searchInp' style={{ padding: 13, fontSize: 17, backgroundColor:'transparent',}} onChange={(e) => setText(e.target.value)} placeholder='SEARCH' type="text" value={text} name="" id="" />
                    </form>

                    <h3 className='regisdetail' style={{ textAlign: 'center' }}>All Products Details</h3>

                    <Button style={{ backgroundColor: '#edc531', padding: 10, border: 'none', cursor: 'pointer' }} onClick={handleClick}>+ Add Product</Button>
                    </div>

                    <Table responsive border={1} hover sx={{ mr: 20 }} component={Paper}>
                        <thead>
                            <tr>
                                <th style={{ paddingLeft: 30, paddingRight: 30, fontSize: 17 }}>#</th>
                                <th style={{ paddingLeft: 30, paddingRight: 30, fontSize: 17 }}>Item</th>
                                <th style={{ paddingLeft: 30, paddingRight: 30, fontSize: 17 }}> Price </th>
                                <th style={{ paddingLeft: 30, paddingRight: 30, fontSize: 17 }}> Qty </th>
                                <th style={{ paddingLeft: 30, paddingRight: 30, fontSize: 17 }}> Edit </th>
                                <th style={{ paddingLeft: 30, paddingRight: 30, fontSize: 17 }}> Delete </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                getProduct.filter((item) => {
                                    return text.toLowerCase() === '' ? item :
                                      item.name.toLowerCase().includes(text);
                                  }).map((e, i) => {
                                    return (
                                        <tr className='rowStyle' key={i}>
                                            <td className='productData'>{i + 1}</td>
                                            <td className='productData' style={{ display: 'flex', alignItems: 'center', margin: 5, padding: 0, justifyContent: 'center' }}><Box component="img" style={{ display: 'flex', justifyContent: 'center' }} src={e.image} width='15%' />
                                                <div className='productDetail'>
                                                    <span>{e.name}</span>
                                                </div>
                                            </td>
                                            <td className='productData'>{e.sPrice ? e.sPrice : e.price}</td>
                                            <td className='productData'>{e.stock}</td>
                                            <td>
                                                <div className='productData' style={{ cursor: 'pointer', color: '#926c15' }} onClick={() => navigate("EditProduct", { state: e })}><EditNoteIcon /></div>
                                            </td>
                                            <td>
                                                <div className='productData' style={{ cursor: 'pointer', color: 'red' }} onClick={() => Delete(e.pId)} sx={{ color: 'black' }}><DeleteIcon /></div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>

                </Box>
            </div>
        </>

    )
}

export default AddProductHome
