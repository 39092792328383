import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { getData } from "../../firebase/firebasemethod";

function ColumnChart (){
  const [chart, setChart] = useState()
  const [data,setData] = useState([])

  React.useEffect(() => {
        getData('ordersNew')
            .then((res) => {
                setData(Object.values(res))
                console.log(res);
                // setLoading(false)
            })
            .catch((err) => {
                alert(err);
                // setLoading(false)
            })
    }, []);
  

    // Week Filter 

//     function getWeekDates() {

//   let now = new Date();
//   let dayOfWeek = now.getDay(); //0-6
//   let numDay = now.getDate();
//   let hours =now.getHours();

//   let start = new Date(); //copy
//   start.setDate(numDay - dayOfWeek+3);



//   let end = new Date(); //copy
//   end.setDate(numDay + ( dayOfWeek - 9));

//   return [start, end];
// }

// let [start, end] = getWeekDates();

// console.log(start.toLocaleString(), end.toLocaleString());

// const dateWeek = {
//     start: start.toLocaleString(),
//     end:   end.toLocaleString()
// }

const date = new Date();


// Days Filter 
let todayDate = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`;

const todayOrders = data.filter((item) => {
    return item.date === todayDate ;
})



let yesterDayDate = `${date.getDate()-1}-${date.getMonth()+1}-${date.getFullYear()}`;

const yesterdaOrders = data.filter((item) => {
    return item.date === yesterDayDate ;
})


// Week Filter



let startDate = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`;
let endDate = `${date.getDate()-6}-${date.getMonth()+1}-${date.getFullYear()}`;

console.log(startDate,endDate);



const weekOrders = data.filter((item) => {
   return item.date >=  endDate && item.date <= startDate; 
  
})





let startDateLast = `${date.getDate()-7}-${date.getMonth()+1}-${date.getFullYear()}`;
let endDateLast = `${date.getDate()-13}-${date.getMonth()+1}-${date.getFullYear()}`;

console.log(startDate,endDate);



const weekOrdersLast = data.filter((item) => {
   return item.date >=  endDateLast && item.date <= startDateLast; 
  
})





// Month Filter

let startDateMonth = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`;
let endDateMonth = `${date.getDate()-30}-${date.getMonth()+1}-${date.getFullYear()}`;

console.log(startDate,endDate);



const MonthOrders = data.filter((item) => {
   return item.date >=  endDateMonth && item.date <= startDateMonth; 
  
})





let startDateMonthLast = `${date.getDate()-7}-${date.getMonth()+1}-${date.getFullYear()}`;
let endDateMonthLast = `${date.getDate()-13}-${date.getMonth()+1}-${date.getFullYear()}`;




const monthOrdersLast = data.filter((item) => {
   return item.date >=  endDateMonthLast && item.date <= startDateMonthLast; 
  
})


// Year Filter

let startDateYear = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`;
let endDateYear = `${date.getDate()-365}-${date.getMonth()+1}-${date.getFullYear()}`;




const yearOrders = data.filter((item) => {
   return item.date >=  endDateYear && item.date <= startDateYear; 
  
})



    const  columnData = {
          
            series: [{
              name: 'This',
              data: [todayOrders.length, weekOrders.length, MonthOrders.length, yearOrders.length, ]
            }, {
              name: 'Last',
              data: [yesterdaOrders.length, weekOrdersLast.length, monthOrdersLast.length,]
            }],
            options: {
              chart: {
                type: 'bar',
                height: 350
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: '55%',
                  endingShape: 'rounded'
                },
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
              },
              xaxis: {
                categories: ['Day', 'Week', 'Month', 'Year'],
              },
              yaxis: {
                title: {
                  text: 'Sales'
                }
              },
              fill: {
                opacity: 1
              },
              tooltip: {
                y: {
                    formatter: function (val) {
                    return  val + " Orders"
                  }
                }
              }
            },
          
          
          };
          return (
            <div>
              <div id="chart">
                <ReactApexChart options={columnData.options} series={columnData.series} type="bar" height={350} />
              </div>
              <div id="html-dist"></div>
            </div>
          );
        }

      


export default ColumnChart