import { Box, Container } from '@mui/system'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Grid } from '@mui/material'
import { BsFillGridFill, BsList } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import Navbar from './Components/Navbar'
import Footer from './Components/Footer'
import { getData } from './firebase/firebasemethod'
import './grid.css'
import FullScreenLoader from './Components/fullScreenLoader'
import { Button, Card, CardOverflow, Link } from '@mui/joy'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

const Product = () => {
  const [gridView, setGridView] = useState(true)
  const [selectValue, setSelectValue] = useState('')
  // const {products} = useProductContext();

  const [products, setProducts] = useState([])

  const get = () => {
    getData('Products').then((res) => {
      const sliceData = res
      setProducts(sliceData)
      // console.log(sliceData)

    }).catch((err) => {
      alert('Product Error')
    })
  }

  useEffect(() => {
    get()
  }, [])



  if (selectValue === 'a-z') {
    products.sort((a, b) => a.name > b.name ? 1 : -1)
  }
  if (selectValue === 'z-a') {
    products.sort((a, b) => a.name < b.name ? 1 : -1)
  }
  if (selectValue === 'lowest') {
    products.sort((a, b) => a.sPrice < b.sPrice ? 1 : -1)
  }
  if (selectValue === 'highest') {
    products.sort((a, b) => a.sPrice > b.sPrice ? 1 : -1)
  }

  // console.log(list)

  const selectSecHandler = (e) => {
    setSelectValue(e.target.value)
  }


 

  useEffect(() => {
    // console.log('hii')
  }, [selectValue])
  // console.log(selectValue)

  // filter section


  const [text, setText] = useState('')

  const [category, setCategory] = useState('')


  // console.log(list.filter((item)=>))



  // console.log(category)

  



  // 


  // product view

  const navigate = useNavigate()

  const handleClick = (e) => {
    navigate(`/product/${e.pId}`, {
      state: e
    })
  }

  const [loader, setLoader] = useState(true)
  setTimeout(() => {
    setLoader(false)
  }, 4000);










  return (
    <div style={{marginTop:110}}>
      {loader ? (<FullScreenLoader />) : (
        <>
          <Navbar />
          <Main>
            <Container>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box sx={{ flexGrow: 1 }}>
                      <section className='product-view--sort'>
                        <Grid container spacing={2}>
                          <Grid item lg={12} md={12}>
                            <div className='sort-filter'>
                              {/* sort data */}

                              <Upper className='sort-secrtion'>
                                <Box style={{ display: 'flex', justifyContent: 'center' }} sx={{ flexGrow: 1 }}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                      <div className='products-data' style={{ display: 'flex', justifyContent: 'center' }}>
                                        <form onSubmit={(e) => e.preventDefault()}>
                                          <input className='searchInp' style={{ padding: 13, fontSize: 17, }} onChange={(e) => setText(e.target.value)} placeholder='SEARCH' type="text" value={text} name="" id="" />
                                        </form>
                                      </div>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={4} lg={4}>
                                      <div className='sorting-list--grid' style={{ display: 'flex', justifyContent: 'center' }}>
                                        <button onClick={() => setGridView(true)} className={gridView ? 'active sort-btn' : 'sort-btn'}>
                                          <BsFillGridFill className='icon' />
                                        </button>
                                        <button onClick={() => setGridView(false)} className={!gridView ? 'sort-btn active' : 'sort-btn '}>
                                          <BsList className='icon' />
                                        </button>
                                      </div>
                                    </Grid>
                                    <Grid item xs={6} sm={6} md={4} lg={4}>
                                      <div className='sort-selection' style={{ display: 'flex', justifyContent: 'center' }}>
                                        <form action='#'>
                                          <lable htmlFor='sort'></lable>
                                          <select onChange={selectSecHandler} style={{ padding: 10 }} name="sort" id="sort" className='sort-selection--style'>
                                            <option value="lowest">Price(lowest)</option>
                                            <option value="" disabled></option>
                                            <option value="highest">Price(highest)</option>
                                            <option value="" disabled></option>
                                            <option value="a-z">(a-z)</option>
                                            <option value="" disabled></option>
                                            <option value="z-a">(z-a)</option>
                                          </select>
                                        </form>
                                      </div>
                                    </Grid>
                                  </Grid>
                                </Box>



                              </Upper>



                            </div>
                          </Grid>
                          <Grid item lg={12}>
                            <div className='main-product'>
                              {/* Product Data */}


                              {
                                gridView ? (

                                  // grid view
                                  <GridSection>


                                    <Box sx={{ backgroundColor: '#f8f9fa', padding: 3, display: 'flex', flexDirection: 'row-reverse' }}>
                                      <div className='grid grid1'>

                                        {
                                          products.filter((item) => {
                                            return text.toLowerCase() === '' ? item :
                                              item.name.toLowerCase().includes(text);
                                          }).map((e, i) => {
                                            return (
                                              <Card>
                                                <div>
                                                  <div onClick={() => handleClick(e)} key={i} className='card' style={{ padding: 3 }}>
                                                    <figure>
                                                      <img src={e.image} width='100%' />
                                                      {e.sPrice?
                                                      <button className="product-card__btn-wishlist">Sale</button>:
                                                    null
                                                    }
                                                      
                                                    </figure>
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                      <div className='card-data' style={{ width: '90%', marginTop: 5 }}>
                                                        {/* <h3 style={{ fontSize: 20, marginTop: 10, textAlign:'center' }}>{name}</h3> */}

                                                        <Link
                                                          onClick={() => handleClick(e)}
                                                          fontWeight="md"
                                                          color="neutral"
                                                          textColor="text.primary"
                                                          overlay
                                                          endDecorator={<ArrowOutwardIcon />}
                                                          style={{ fontSize: 20 }}
                                                        >
                                                          {e.name}
                                                        </Link>

                                                        <div>
                                                          <h3 style={{ fontSize: 15, marginTop: 10 }}>{e.sPrice ? `Rs:${e.sPrice}` : `Rs:${e.price}`} <s style={{ color: 'gray' }}>{e.sPrice ? `Rs:${e.price}` : null}</s></h3>
                                                          {/* <div onClick={() => handleClick(item)} className='box' style={{ backgroundColor: 'black', borderRadius: 20, marginTop: 15,padding:5,marginBottom:10, cursor:'pointer' }}>
          <button className='productBtn' >Shop Now</button>
            </div> */}


                                                        </div>
                                                        <div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <CardOverflow onClick={() => handleClick(e)}>
                                                  <Button style={{ fontSize: 15, backgroundColor:'#efb113'}} variant="solid" size="lg">
                                                    Shop Now
                                                  </Button>
                                                </CardOverflow>
                                              </Card>
                                            )
                                          })
                                        }
                                      </div>

                                    </Box>
                                  </GridSection>







                                ) : (

                                  // list view


                                  <ListSection>
                                    <Container>
                                      <div >
                                        {
                                          products.filter((item) => {
                                            return text.toLowerCase() === '' ? item :
                                              item.name.toLowerCase().includes(text);
                                          }).map((e, i) => {
                                            return (
                                              <Box sx={{ flexGrow: 1, marginBottom: 5 }} className='card'>
                                                <Grid container spacing={2}>
                                                  <Grid item lg={6} md={6} sm={12} xs={12}>
                                                    <figure>
                                                      <img src={e.image} alt={e.id} />
                                                    </figure>
                                                  </Grid>
                                                  <Grid item lg={6} md={6} sm={12} xs={12}>
                                                    <div className='card-data'>
                                                    <Link
                                                          onClick={() => handleClick(e)}
                                                          fontWeight="md"
                                                          color="neutral"
                                                          textColor="text.primary"
                                                          overlay
                                                          endDecorator={<ArrowOutwardIcon />}
                                                          style={{ fontSize: 30, marginTop:30 }}
                                                        >
                                                          {e.name}
                                                        </Link>

                                                        <div>
                                                           <h3 style={{ fontSize: 25, marginTop: 20 }}>{e.sPrice ? `Rs:${e.sPrice}` : `Rs:${e.price}`} <s style={{ color: 'gray' }}>{e.sPrice ? `Rs:${e.price}` : null}</s></h3>
                                                          {/* <div onClick={() => handleClick(item)} className='box' style={{ backgroundColor: 'black', borderRadius: 20, marginTop: 15,padding:5,marginBottom:10, cursor:'pointer' }}>
          <button className='productBtn' >Shop Now</button>
            </div> */}


                                                        </div>
                                                        <CardOverflow onClick={() => handleClick(e)}>
                                                  <Button style={{ fontSize: 15, marginTop:50, marginBottom: 30 , backgroundColor:'#efb113'}} variant="solid"color="warning" size="lg">
                                                    Shop Now
                                                  </Button>
                                                </CardOverflow>
                                                    </div>
                                                  </Grid>
                                                </Grid>
                                              </Box>
                                            )
                                          })
                                        }
                                      </div>
                                    </Container>
                                  </ListSection>


                                )
                              }
                            </div>

                          </Grid>
                        </Grid>
                      </section>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Main>
          <Footer />
        </>
      )}

    </div>
  )
}

const GridSection = styled.section`
figure {
  width: auto;
  display: flex;
  height: 250px;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: all 0.5s linear;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.2s linear;
    cursor: pointer;
  }
  &:hover::after {
    width: 100%;
  }
  &:hover img {
    transform: scale(1.2);
  }img {
    margin-top: 1.5rem;
    transition: all 0.2s linear;
  }
}`
const ListSection = styled.section`
  padding: 9rem 0;
  .container {
    max-width: 120rem;
  }
  .grid {
    gap: 4rem;
  }
  figure {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: all 0.5s linear;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      transition: all 0.2s linear;
      cursor: pointer;
    }
    &:hover::after {
      width: 100%;
    }
    &:hover img {
      transform: scale(1.2);
    }
    img {
      max-width: 90%;
      margin-top: 1.5rem;
      height: 20rem;
      transition: all 0.2s linear;
    }
  }
  .card {
    border: 0.1rem solid rgb(170 170 170 / 40%);
    .card-data {
      padding: 0 2rem;
    }
    h3 {
      margin: 2rem 0;
      font-weight: 300;
      font-size: 2.4rem;
      text-transform: capitalize;
    }
    .btn {
      margin: 2rem 0;
      background-color: #fff;
      border: 0.1rem solid #edc531;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #edc531;
      &:hover {
          background-color: #edc531;
          color: #fff
      }
      &:hover a {
        color: #fff;
      }
      a {
        color: rgb(98 84 243);
        font-size: 1.4rem;
      }
    }
    .btn-main .btn:hover {
      color: #fff;
    }
  }
`;



const Filter = styled.section`
  padding: 5rem 0;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  h3 {
    padding: 2rem 0;
    font-size: bold;
  }
  .filter-search {
    input {
      padding: 0.6rem 1rem;
      width: 80%;
    }
  }
  .filter-category {
    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1.4rem;
      button {
        border: none;
        background-color: ${({ theme }) => theme.colors.white};
        text-transform: capitalize;
        cursor: pointer;
        &:hover {
          color: ${({ theme }) => theme.colors.btn};
        }
      }
      .active {
        border-bottom: 1px solid #000;
        color: ${({ theme }) => theme.colors.btn};
      }
    }
  }
  .filter-company--select {
    padding: 0.3rem 1.2rem;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.text};
    text-transform: capitalize;
  }
  .filter-color-style {
    display: flex;
    justify-content: center;
  }
  .color-all--style {
    background-color: transparent;
    text-transform: capitalize;
    border: none;
    cursor: pointer;
  }
  .btnStyle {
    width: 2rem;
    height: 2rem;
    background-color: #000;
    border-radius: 50%;
    margin-left: 1rem;
    border: none;
    outline: none;
    opacity: 0.5;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  }
  .active {
    opacity: 1;
  }
  .checkStyle {
    font-size: 1rem;
    color: #fff;
  }
  .filter_price {
    input {
      margin: 0.5rem 0 1rem 0;
      padding: 0;
      box-shadow: none;
      cursor: pointer;
    }
  }
  .filter-shipping {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .filter-clear .btn {
    background-color: #ec7063;
    color: #000;
  }
`;

const Main = styled.section`
  .grid-filter-column {
    grid-template-columns: 0.2fr 1fr;
  }
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .grid-filter-column {
      grid-template-columns: 1fr;
    }
  }
`;


const Upper = styled.section`
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
  .sorting-list--grid {
    display: flex;
    gap: 2rem;
    .sort-btn {
      padding: 0.8rem 1rem;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .icon {
      font-size: 1.6rem;
      color:#edc531;
    }
    .active {
      background-color: ${({ theme }) => theme.colors.black};
      color:#edc531;
    }
  }
  .sort-selection .sort-selection--style {
    padding: 0.9rem;
    cursor: pointer;
    .sort-select--option {
      padding: 0.5rem 0;
      cursor: pointer;
      height: 2rem;
      padding: 10px;
    }
  }
`;

export default Product
