// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAee1IP4X-Fxpae_nIOVh7cuUVYPf9KHfA",
  authDomain: "al-musawir.firebaseapp.com",
  projectId: "al-musawir",
  storageBucket: "al-musawir.appspot.com",
  messagingSenderId: "11349792225",
  appId: "1:11349792225:web:9e60214326a22bdec76ea0",
  measurementId: "G-65SYHDSWY1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default app;